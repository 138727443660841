<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow class="pb-3">
            <CCol sm="10">
              <h4 class="text-uppercase">Rate Lists</h4>
            </CCol>

            <CCol sm="2" class="text-right">
              <router-link
                  class="btn btn-primary"
                  to="/bills/rates/create"
              >
                + New Rate
              </router-link>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr class="text-center">
                    <th scope="col">SL</th>
                    <th scope="col">Ward Description</th>
                    <th scope="col">Rate</th>
                    <th scope="col">Unit</th>

                    <th scope="col">Due Date Duration</th>
                    <th scope="col">VAT</th>
                    <th scope="col">Surcharges</th>

                    <th scope="col">Activated</th>
                    <th scope="col">Deactivated</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">Action</th>
                  </tr>
                  </thead>
                  <tbody v-if="rates.length">
                  <tr class="text-center" v-for="(item, index) in rates" :key="item.id">
                    <td scope="row">{{ meta.from + index }}</td>
                    <td scope="row">{{ item.wr_desc }}</td>
                    <td scope="row"><strong>Domestic:</strong> {{ item.w_rate }} <br><strong>Non-domestic:</strong> {{ item.w_rate2 }}</td>
                    <td scope="row"><strong>Avg Unit:</strong> {{ item.avg_unit }} <br><strong>Unit Desc:</strong> {{ item.unit_desc }}</td>

                    <td scope="row">{{ item.due_date_duration }} Days</td>
                    <td scope="row"><strong>Vat:</strong> {{ item.vat }}% <br> <strong>Reg.:</strong> {{item.vat_reg_no}}</td>
                    <td scope="row">
                      <ul class="price-list">
                        <li v-for="(surcharge, index) in item.surcharges" :key="index"><strong>Month: </strong>{{ surcharge.month_no }}{{nth(surcharge.month_no)}}, <strong>Charge: </strong>{{ formatPrice(surcharge.charge) }}%</li>
                      </ul>
                    </td>

                    <td scope="row">{{ item.activated | dateFormat }}</td>
                    <td scope="row">{{ item.deactivated | dateFormat }}</td>
                    <td scope="row">{{ item.status }}</td>
                    <td scope="row">
                      <router-link :to="{name:'EditRate', params:{id:item.id}}"
                                   class="btn btn-warning btn-sm ml-2 text-white">Show
                      </router-link>
                      <button type="button" @click="rateDelete(item.id)" class="btn d-none btn-danger btn-sm ml-2 text-white">
                        Delete
                      </button>
                    </td>

                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="10">
                      <h5 class="text-center">Data Not Available.</h5>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
              v-if="rates.length"
              align="center"
              :pages="rows"
              :active-page.sync="filter.currentPage"
              @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PriceListComp",
  data() {
    return {
      filter: {
        column: "",
        keyword: "",
        currentPage: 1,
      },
      breadcrumbs: [
        {text: "Home", path: "/dashboard", isActive: false},
        {text: "Rates", path: "", isActive: true}
      ],
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  methods: {
    nth(n) {
      return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$router.replace({name: "RateList", query: this.filter});
      this.$store.dispatch("Rates/getRates", this.filter);
    },
    rateDelete(id) {
      this.$confirm({
        message: `Are you sure, want to delete the this?`,
        button: {
          no: "No",
          yes: "Yes, Delete",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store
                .dispatch("Rates/delete", id)
                .then(() => {
                  this.$store.dispatch("Settings/loading", false);
                  this.$toastr.s("Rate Successfuly Deleted.", "Deleted!");
                  this.$store.dispatch("Rates/getRates", this.filter);
                }, (error) => {
                  this.$toastr.e(error.response.data.message, "Failed!");
                  this.$store.dispatch("Settings/loading", false);
                });
          }
        },
      });
    }

  },
  computed: {
    ...mapGetters("Rates", ["rates", "rows", "perPage", "meta"]),
  },
  mounted() {
    this.$store.dispatch("Rates/getRates", this.filter);
  },
};
</script>

<style scoped>
.card {
  border: none;
  padding: 15px 15px 0;
}

tbody:before {
  content: "@";
  display: block;
  line-height: 0;
  text-indent: -99999px;
}

.hide-footer >>> footer {
  display: none;
}

.custom-close-button >>> .close {
  color: aliceblue;
}
</style>

<style lang="scss" scoped>
.badge {
  font-size: 12px;
  padding: 5px 7px;
}

div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
          vertical-align: middle;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          vertical-align: middle;

          button {
            font-size: 13px;
          }

          span {
            cursor: pointer;
          }

          span .color-view {
            color: #5e76e7;
            padding: 10px;
          }
        }

        td:nth-child(1) {
          color: #5e76e7;
        }

        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}

.price-list {
  list-style-type: none;
  padding: 8px 15px;
  background-color: #efefef;
  border-radius: 6px;
  font-size: 14px;
}
</style>



